<template>
<section class="brandinfo-wrapper">
    <h1 class="header-title">基本信息</h1>

    <!-- form -->
    <el-form class="form-content" ref="form" :model="form" :rules="rules" label-width="100px" label-position="left">
        <el-form-item label="品牌名称" prop="name">
            <el-input v-model="form.name" maxlength="12" placeholder="最多输入12个字符"></el-input>
        </el-form-item>
        <el-form-item label="品牌LOGO" prop="logo">
            <p class="tip">上传图片的格式png/jpeg/jpg(建议尺寸100*100像素)</p>
            <el-upload
                action=""
                :http-request="uploadHandler1"
                :before-upload="beforeUploadHandler"
                :file-list="form.logo"
                :show-file-list="false"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove1">
                <div class="el-upload--picture-card" v-if="form.logo.length==0">
                    <p  class="upload-custom flex flex-column align-center flex-center">
                        <i class="el-icon-plus el-icon"></i><br/>
                        <span >添加上传图片</span>
                    </p>
                </div>
                <img v-else :src="form.logo[0].url" alt="" class="upload-img">
            </el-upload>
        </el-form-item>
        <el-form-item label="小店背景" prop="bg">
            <p class="tip">上传图片的格式png/jpeg/jpg(建议尺寸宽*高像素)具体尺寸由设计师提供</p>
            <el-upload
                action=""
                :http-request="uploadHandler2"
                :show-file-list="false"
                :file-list="form.bg"
                :before-upload="beforeUploadHandler"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove2">
                <div class="el-upload--picture-card" v-if="form.bg.length==0">
                    <p  class="upload-custom flex flex-column align-center flex-center">
                        <i class="el-icon-plus el-icon"></i><br/>
                        <span >添加上传图片</span>
                    </p>
                </div>
                <img v-else :src="form.bg[0].url" alt="" class="upload-img">
            </el-upload>
        </el-form-item>
        <el-form-item label="客服微信" prop="weixin">
            <el-input v-model="form.weixin" placeholder="最多输入客服微信"></el-input>
        </el-form-item>
        <el-form-item>
            
        </el-form-item>
    </el-form>
    <div class="footer-btn">
        <el-button>取消</el-button>
        <el-button type="primary" @click="saveHandler">保存</el-button>
    </div>
    <!-- 图片放大 -->
    <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
</section>
</template>

<script>
let init = false;
function validateLogo(rule, value, callback){
    if(value.length==0){
        callback(new Error('请上传logo'))
    }else{
        callback()
    }
}

function validateBg(rule, value, callback){
    if(value.length==0){
        callback(new Error('请上传背景图'))
    }else{
        callback()
    }
}

import utils from '@/utils/utils.js';
export default {
    data(){
        return {
            form:{
                name:'',
                logo:[],
                bg:[],
                weixin:''
            },
            rules:{
                name:[{required: true, message: '请输入品牌名称', trigger: 'blur'}],
                logo:[{required: true, validator:validateLogo, trigger: 'change'}],
                bg:[{required: true, validator:validateBg, trigger: 'change'}],
                weixin:[{required: true, message: '请输入客服微信', trigger: 'blur'}],
            },
            dialogVisible: false,
            dialogImageUrl: ''
        }
    },
    created(){
        this.getBaseInfo();
    },
    methods:{

        async getBaseInfo(){
            const rs = await this.$https.cloud({
                action:'global',
                type:'getBase'
            });
            console.log(rs);
            if(rs.code!=0) return;
            this.form.name = rs.data.data[0].data.brandName.value;
            this.form.weixin = rs.data.data[0].data.weChatNumber.value;
            let imgList = [{fileid:rs.data.data[0].data.logo.value},{fileid:rs.data.data[1].data.shopBackGround.value}];
            imgList = await this.$https.getImagePath(imgList);
            console.log(imgList);
            imgList.forEach(item=>{
                item.url = item.download_url
            });
            this.form.logo = [imgList[0]];
            this.form.bg = [imgList[1]];
        },



        //  ==================
        handlePictureCardPreview(file){
            this.dialogVisible = true;
            this.dialogImageUrl = file.url;
        },
        beforeUploadHandler(file){
            const isLt3M = file.size / 1024 / 1024 < 3;
            if (!isLt3M) {
            this.$message.error('上传图片大小不能超过 3MB!');
            }
            return isLt3M;
        },
        handleRemove1(file,fileList){
            this.form.logo = [];
        },
        handleRemove2(file,fileList){
            this.form.bg = [];
        },
        uploadHandler1(file,fileList){
            
            this.upLoadCode(file).then(rs=>{

                this.form.logo = [rs];
            });
        },
        uploadHandler2(file,fileList){
            debugger
            this.upLoadCode(file).then(rs=>{

                this.form.bg = [rs];
            });
        },
        async upLoadCode(file){
            const localPath = utils.Img_URL(file.file);
            const rs = await this.$https.uploadImage('setting',file.file);
            return {
                url: localPath,
                fileid: rs,
                uid: file.file.uid
            };
        },
        async saveHandler(){
            if(init){
                this.$message.error('请不要重复提交');
                return;
            }
             this.$refs.form.validate( async valid=>{
                if(!valid) return;
                const rs = await this.$https.cloud({
                    action:'global',
                    type:'setBase',
                    formData:{
                        brandName: this.form.name,
                        logo: this.form.logo[0].fileid,
                        shopBackGround: this.form.bg[0].fileid,
                        weChatNumber: this.form.weixin
                    }
                });
                if(rs.code==0){  
                    init = true;
                    setTimeout(()=>{init=false;},5*1000);
                    this.$message.success(rs.msg);
                }else{

                    this.$message.error(rs.msg);
                }
            });
            
        },
    }
}
</script>

<style lang="scss" scoped>
.brandinfo-wrapper {
    padding: 10px;
    height: 100%;
    position: relative;
    background: #fff;

    .header-title {
        font-size: 16px;
        line-height: 22px;
        padding: 13px 0 12px 0;
        border-bottom: 1px solid #D9D9D9;
    }

    .form-content {
        max-width: 800px;
        margin-top: 40px;
    }

    .upload-img {
        width: 148px;
        height: 148px;
        border-radius: 6px;
    }
}
</style>